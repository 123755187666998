.popup-modal {
  position: fixed;
  z-index: 1031; /* dall'inspect ho notato che z index del nav bar è 1030 This is typical for navigation bars in frameworks like Bootstrap*/
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Sfondo semi-trasparente */
}

.popup-content {
  background-color: #ffffff;
  margin: auto; /* Centrato verticalmente e orizzontalmente */
  padding: 20px;
  border: 10px solid #f7c200; /* Bordo nero solido come cornice */
  width: 80%; /* O la larghezza che preferisci */
  max-width: 600px; /* Imposta una larghezza massima */
  border-radius: 50px; /* Bordi arrotondati */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Ombra leggera per profondità */
  position: relative; /* Per posizionamento assoluto di elementi interni, se necessario */
}

.close-btn {
  color: #aaa;
  float: right;
  font-size: 50px; /* Aumenta la dimensione del font */
  font-weight: bold;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0 10px; /* Aggiunge un po' di padding intorno per una migliore area cliccabile */
  line-height: 1; /* Assicurati che l'altezza della linea non espanda troppo l'area */
  margin-top: -10px; /* Opzionale: aggiusta la posizione se necessario */
}

.close-btn:hover,
.close-btn:focus {
  color: black;
  transform: scale(1.5); /* Effetto hover: leggermente più grande */
}

/* Stili specifici per l'immagine QR */
.popup-modal img {
  max-width: 100%; /* Assicurati che l'immagine si adatti al contenitore */
  height: auto;
  border-radius: 10px; /* Opzionale: bordi arrotondati anche per l'immagine */
}

/* Stili per il bottone di download */
.download-btn {
  display: block;
  width: 60%; /* Riduce la larghezza del bottone */
  padding: 15px;
  margin: 20px auto 0 auto; /* Aggiunge margine superiore e centra il bottone */
  background-color: #f7c200;
  color: white;
  border: none;
  border-radius: 25px;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.download-btn:hover,
.download-btn:focus {
  background-color: #e6b800;
  transform: scale(1.05);
}
